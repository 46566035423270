import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
const Image = (props) => {
  const {
    filename,
    type = "default",
    alt,
    sizes = "(max-width: 400px) 100vw, (max-width: 600px) 100vw,(max-width: 2000px) 100vw, (max-width: 4000px) 100vw",
  } = props;

  const images = useStaticQuery(graphql`
    query regularImage {
      data: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "plainpics" }
        }
      ) {
        edges {
          node {
            relativePath
            default: childImageSharp {
              fluid(quality: 85) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const image = images.data.edges.find((n) => {
    return n.node.relativePath.includes(filename);
  });

  if (!image) {
    return null;
  }

  return (
    <Img
      alt={alt}
      className={props.class}
      fluid={{
        ...image.node[type].fluid,
        sizes: sizes,
      }}
    />
  );
};
export default Image;
