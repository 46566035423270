import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Minislider from "../components/minislider";
import Svgsprite from "../components/svgsprite";
import Youtube from "../components/youtube";
import Image from "../components/image";
import store from "../images/svg/store.svg";
import play from "../images/svg/play.svg";
import styles from "../css/chartplotter.module.scss";

const IndexPage = () => (
  <Layout>
    <SEO title="Chartplotter" />
    <section data-side="navi apps" className="contentbox wrap --fullw">
      <div className="textcontent">
        <h2>
          Chartplotter <span>powered by Boatpilot</span>
        </h2>
        <p>
          Chartplotter applications from BoatPilot have been developing since
          2012. In this non-profit project we are constantly developing the
          platform, while the sailors' community is appending new information.
          Due to the fact that the sailors' communities are one of the most
          united, we managed to do the incredible and make a completely free
          product superior to the quality of commercial analogues.
        </p>
        <h3>Download for free</h3>
        <p>
          The application is being developed for android and ios platforms and
          you can download it for free now.
        </p>
        <p>
          <a
            style={{ marginRight: `1em` }}
            target="_blank"
            rel="noreferrer"
            href="https://itunes.apple.com/us/app/boatpilot/id1335136322?mt=8"
          >
            <Svgsprite
              svgclass="storebtn"
              name={store.id}
              vbox={store.viewBox}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=me.piratas.boatpilot.eu"
            target="_blank"
            rel="noreferrer"
          >
            <Svgsprite svgclass="storebtn" name={play.id} vbox={play.viewBox} />
          </a>
        </p>
      </div>
      <div className="minislider">
        <Minislider
          names={[
            "plotter_2.jpg",
            "plotter_3.jpg",
            "plotter_4.jpg",
            "plotter_7.jpg",
            "plotter_9.jpg",
            "plotter_10.jpg",
            "plotter_11.jpg",
            "plotter_12.jpg",
            "plotter_13.jpg",
          ]}
        />
      </div>
      <div className="videocontent">
        <Youtube
          tubeid="lsEGtPeWJ-w"
          posterimagename="tubeposter_chartplotter.jpg"
        />
      </div>
    </section>
    <section data-side="module" className="contentbox wrap --fullw --dark">
      <div className="textcontent">
        <h2>
          Maps & charts <span>filled with community</span>
        </h2>
        <p>
          Like any other product, BoatPilot provides all basic data, including
          information about marinas, navigation signs and lighthouses,
          infrastructure such as services and fueling stations. Special emphasis
          is placed on media content. From our point of view, photography is an
          essential element for assessing navigation conditions in the 21st
          century. The photo will say more about the bay or marina than hundreds
          of words or text description. Among all regions represented in the
          world, BoatPilot confidently ranks first in the number of yacht
          infrastructure photographs.
        </p>
        <p>
          Another significant component is charts. The charts of all regions
          that are already presented in BoatPilot are also free. This was made
          possible thanks to enthusiasts from all around the world, who in 2012
          believed that sailors can repeat the success of the OpenStreetMap.
        </p>
        <p>
          Thousands of hours of painstaking digitization and mapping from
          various sources allow more than 60,000 seamen to use this information
          for free, without robbing private companies’ fees selling each region
          in tens of pieces for maximum profit, with minimal refresh rates. Our
          charts are updated constantly and in real time with the help of
          information obtained directly from the sonars of thousands of yachts
          equipped with BoatGod modules.
        </p>
      </div>

      <div className="videocontent">
        <Image class="image-top-left" filename="chartplotter.png" />
      </div>
    </section>
    <section data-side="earn" className="contentbox wrap --fullw --novideo ">
      <div className="textcontent --max80">
        <h2>
          Get paid <span>for activity </span>
        </h2>
        <p>
          We consider all the content that our users add to their intellectual
          property. And we offer to earn money on it.
        </p>
        <p>
          How does it work? Very simple. By adding any information to BoatPilot,
          you will receive crypto tokens. In fact, these tokens confirm your
          rights to this information. Then you are free to dispose of them at
          your discretion. Hold for the future, pass or give and sell. Our
          tokens are circulating on several large crypto asset exchanges. Here
          you can exchange your tokens for cash.
        </p>
        <h3>
          Who is the buyer and why is someone can be interested in buying your
          digital content rights?
        </h3>
        <p>
          The first party concerned is our company. We are eager to be the owner
          of all content rights. Therefore, we direct a significant part of our
          profit to the redemption of content rights. In other words, we
          constantly buy tokens on exchanges.{" "}
        </p>
        <p>
          The second type of buyer is investors. People who have temporarily
          free means closely monitor the success of our company. They buy tokens
          in times of falling demand to sell them more expensive when demand
          rises (for example, by the end of the fiscal year, when we know how
          much money will be used to buy back rights).
        </p>
        <p>
          hus, the digital content rights system exists independently of the
          BoatPilot, which guarantees users free access to manage their assets.
        </p>
        <p>
          We would appreciate you helping the community by adding marinas,
          anchorages or reviews. You can do it right in the sea. But this is
          also a great activity for long autumn and winter evenings. Remember
          what places you visited. Share navigation conditions, infrastructure
          information and experiences with other skippers. Warn of difficulties,
          recommend solutions. We are sure that only with joint community
          efforts we can create a worthwhile sea navigator and pilot.{" "}
        </p>
      </div>
    </section>

    <section data-side="" className="contentbox wrap --fullw --novideo --dark ">
      <div className="textcontent --max80">
        <h2>
          Community <span>driven</span>
        </h2>
        <p>
          We would appreciate you helping the community by adding marinas,
          anchorages or reviews. You can do it right in the sea. But this is
          also a great activity for long autumn and winter evenings. Remember
          what places you visited. Share navigation conditions, infrastructure
          information and experiences with other skippers. Warn of difficulties,
          recommend solutions. We are sure that only with joint community
          efforts we can create a worthwhile sea navigator and pilot.
        </p>
      </div>
    </section>
    <section data-side="patch" className="contentbox wrap --fullw --novideo  ">
      <div className="textcontent">
        <h2>
          Maps <span>data patch</span>
        </h2>
        <p className="--max80">
          We are asserting community geospartical data and patches for boatpilot
          in{" "}
          <a
            target="_blank"
            href="https://en.wikipedia.org/wiki/Shapefile"
            rel="noreferrer"
          >
            ERSI Shapefiles
          </a>{" "}
          format. Eeach deep describing feature should have decimal deep
          attribute.
        </p>
        <div className={styles.steps}>
          <div data-num="1" className={styles.item}>
            <div className={styles.innergray}>
              <h3>Download QGIS application</h3>
              <p>
                {" "}
                QGIS is free and opensource software available for most
                operation systems. You can download it from its offical site:
                <a
                  href="https://www.qgis.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  qgis.org
                </a>
              </p>
              <p>
                QGIS is a user friendly Open Source Geographic Information
                System (GIS) licensed under the GNU General Public License. QGIS
                is an official project of the Open Source Geospatial Foundation
                (OSGeo). It runs on Linux, Unix, Mac OSX, Windows and Android.
              </p>
              <div className={styles.photosamples}>
                <Minislider
                  slidetoview="2"
                  isshownav={false}
                  names={["community_1.jpg", "community_2.jpg"]}
                />
              </div>
            </div>
          </div>
          <div data-num="2" className={styles.item}>
            <div className={styles.innergray}>
              <h3>Download world coastline shapefile</h3>
              <p>
                The world coastline shape will be required for presise
                georeferencing. It distributed freely and can be downloaded from
                OpenStreetMap Coastlines. WGS84 version is required.
              </p>
              <p>
                OpenStreetMapData.com provides geodata based on information from
                OpenStreetMap, processed for easier use for rendering maps and
                other purposes.
              </p>
              <div className={styles.photosamples}>
                <Minislider
                  slidetoview="2"
                  isshownav={false}
                  names={["community_3.jpg", "community_4.jpg"]}
                />
              </div>
            </div>
          </div>
          <div data-num="3" className={styles.item}>
            <div className={styles.innergray}>
              <h3>Create QGIS project and add coastline shape layer</h3>
              <p>
                Start QGis and add ERSI Shapefile layer with downloaded
                coastline shape. Then using raster->georeferencing tool add
                raster layer to project.
              </p>
              <p>
                You can find more information about georeferencing tool here:
                <a
                  href="http://www.qgistutorials.com/en/docs/georeferencing_basics.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Tutorial: Georeferencing Topo Sheets and Scanned Maps/a>
                </a>
                ,
                <a
                  href="https://www.youtube.com/watch?v=A-jBYc9pLiQ"
                  target="_blank"
                  rel="noreferrer"
                >
                  QGIS Tutorial 02 : Georeferencing using QGIS
                </a>
              </p>
              <div className={styles.photosamples}>
                <Minislider
                  slidetoview="2"
                  isshownav={false}
                  names={["community_5.jpg", "community_6.jpg"]}
                />
              </div>
            </div>
          </div>
          <div data-num="4" className={styles.item}>
            <div className={styles.innergray}>
              <h3>Create vector layer with your data</h3>
              <p>
                Add new shapefile layer for your data. Select Line as type,
                WGS84 as CRS and add new attribute named deep of decimal type.
              </p>
              <p>
                Save it under deep-counter name. You can another types for point
                deeps or deep areas using Point or Polygon as layer type
              </p>
              <div className={styles.photosamples}>
                <Minislider
                  slidetoview="1"
                  isshownav={false}
                  names={["community_7.jpg"]}
                />
              </div>
            </div>
          </div>
          <div data-num="5" className={styles.item}>
            <div className={styles.innergray}>
              <h3>Draw deeps data (Line)</h3>
              <p>
                Switch shapefile layer to edit mode. Add deep lien using Add
                feature tool point by point over raster layer. When feature
                created add Id and deep as properties. Ids should be unique for
                your file. You can use ascending number sequence as ids.
              </p>
              <div className={styles.photosamples}>
                <Minislider
                  slidetoview="1"
                  isshownav={false}
                  names={["community_8.jpg"]}
                />
              </div>
            </div>
          </div>
          <div data-num="6" className={styles.item}>
            <div className={styles.innergray}>
              <h3>Add more data and send files</h3>
              <p>
                Repeat previous for all your shape files and save layer when you
                are ready. Create zip archive with 4 files: deep-counter.cpg,
                deep-counter.dbf, deep-counter.shp, deep-counter.shx. Send files
                to <a href="mailto:info@boatpilot.me">info@boatpilot.me</a> .
                Our content managers will review and apply your changes to our
                geospatial database.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
